<template>
  <div>
     <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="进仓单号">
          <el-input v-model="dataForm.inWarehouseNo" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="客户名称">
          <el-input v-model="dataForm.customerName" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="dataForm.vehiclePlate" placeholder="支持模糊查询" clearable></el-input>
        </el-form-item>
        <el-form-item label="业务员" prop="saleIdList" >
          <el-select class="item-choose"
                     v-model="dataForm.saleIdList"
                     size="small"
                     filterable clearable placeholder="请选择">
            <el-option
              v-for="item in  userJobMap.SALE"
              :key="item.realName"
              :label="item.realName"
              :value="item.realName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务经理" prop="saleAdminIdList">
          <el-select class="item-choose"
                     v-model="dataForm.saleAdminIdList"
                     size="small"
                     filterable clearable placeholder="请选择"
          >
            <el-option
              v-for="item in userJobMap.SALE_ADMIN"
              :key="item.realName"
              :label="item.realName"
              :value="item.realName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客服员" prop="careIdList">
          <el-select class="item-choose"
                     v-model="dataForm.careIdList"
                     size="small"
                     filterable clearable placeholder="请选择"
          >
            <el-option
              v-for="item in  userJobMap.CARE"
              :key="item.realName"
              :label="item.realName"
              :value="item.realName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客服经理" prop="careAdminIdList">
          <el-select class="item-choose"
                     v-model="dataForm.careAdminIdList"
                     size="small"
                     filterable clearable placeholder="请选择"
          >
            <el-option
              v-for="item in  userJobMap.CARE_ADMIN"
              :key="item.realName"
              :label="item.realName"
              :value="item.realName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款状态" prop="customerInfowriteStatus">
          <el-select class="item-choose" v-model="dataForm.customerInfowriteStatus" style="width: 100%;" filterable clearable
                     placeholder="请选择">
            <el-option label="已付款" value="1"></el-option>
            <el-option label="未付款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="提货时间">
          <el-date-picker
            v-model="dataForm.takeGoodsTime"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" v-if="this.newAuth('cost:base:export')" @click="downLoad">导出</el-button>
        </el-form-item>
        <el-form-item style="text-align: right;float: right;">
          <div class="operationList">
            <el-button size="small" type="success" v-if="newAuth('customer:receivable:update')" @click="openWriteOffPopup(1)"
                       :disabled="!selectFeeData.length">已付款核销
            </el-button>
            <el-button size="small" type="success" v-if="newAuth('customer:receivable:update')" @click="batchAudit(2)"
                       :disabled="!selectFeeData.length">反核销
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :data="dataList" border size="small" :height="tableHeight"
                :span-method="this.arraySpanMethod"
                :summary-method="getSummaries"
                show-summary
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                v-loading="dataListLoading" style="width: 100%;"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="35" fixed="left" align="center"></el-table-column>
        <el-table-column label="提货时间" width="105" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{scope.row.orderInfo.takeGoodsDate + ' ' + scope.row.orderInfo.takeGoodsTime}}
          </template>
        </el-table-column>
        <el-table-column label="进仓单号" prop="orderInfo.inWarehouseNo" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单类型" :formatter="orderType" prop="orderInfo.orderType" width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="订单车型" :formatter="dictConvert" width="80" :show-overflow-tooltip="true"  prop="orderInfo.vehicleType">
        </el-table-column>
<!--        <el-table-column label="指派车型" prop="vehiclePlan.vehicleTypeName" width="80" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
        <el-table-column label="客户名称" prop="orderInfo.customerName" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="提货地址" prop="fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="报关方式" :formatter="entranceType" prop="orderInfo.entranceType" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="收货地址" prop="giveAddressInfo.fullAddress" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机车牌"  prop="vehiclePlan.vehiclePlate" width="70" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机姓名"  prop="vehiclePlan.driverName" width="60"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="司机电话"  prop="vehiclePlan.driverPhone" width="90"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="运费" prop="costInfo.freightFee" :formatter="getNumValue" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="加点费" prop="costInfo.addFee" :formatter="getNumValue" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="业务员提成" prop="customerInfo.salesmanFee" :formatter="getNumValue" width="80"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="业务经理提成" prop="customerInfo.salesmanManagerFee" :formatter="getNumValue" width="80"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客服专员提成" prop="customerInfo.serviceFee" :formatter="getNumValue" width="80"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="客服经理提成" prop="customerInfo.managerFee" :formatter="getNumValue" width="80"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="费用小计" prop="payableAmount" :formatter="getNumValue" width="80" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="已付小计" prop="paidAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="未付小计" prop="notPayableAmount" :formatter="getNumValue" width="70"  :show-overflow-tooltip="true">
        </el-table-column>
        <!--<el-table-column label="客户名称" prop="orderInfo.customerName" width="70" :show-overflow-tooltip="true">-->
        <!--</el-table-column>-->
        <el-table-column prop="status" label="订单状态" width="70" :show-overflow-tooltip="true" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.status == 0 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">未提交</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 1 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">待审核</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 2 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="warning">调度中</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 3 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small">已调度</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 8 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="success">已完成</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 9 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">异常结束</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 10 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">审核拒绝</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 11 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="danger">订单退回</el-tag>
            <el-tag v-else-if="scope.row.orderInfo.status == 12 && $store.state.common.spanStatusArray.indexOf(scope.row.orderInfo.status) > -1" size="small" type="info">已取消</el-tag>
            <el-tag v-else-if="scope.row.status == 4" size="small">待出车</el-tag>
            <el-tag v-else-if="scope.row.status == 5" size="small" type="success">已出车</el-tag>
            <el-tag v-else-if="scope.row.status == 6" size="small" type="success">已到达</el-tag>
            <el-tag v-else-if="scope.row.status == 7" size="small" type="success">已装货</el-tag>
            <el-tag v-else-if="scope.row.status == 15" size="small" type="success">卸货到场</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="件数" prop="orderGoods.pieceNum" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="体积" prop="orderGoods.volume" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="重量" prop="orderGoods.weight" width="40"  :show-overflow-tooltip="true">
        </el-table-column>
        <!--<el-table-column label="客户单号"  prop="orderInfo.customerSelfNo" width="100" :show-overflow-tooltip="true">-->
        <!--</el-table-column>-->
        <!--<el-table-column label="客户联系人"  prop="orderInfo.customerContactName" width="60"  :show-overflow-tooltip="true">-->
        <!--</el-table-column>-->
        <!--<el-table-column label="客户联系电话"  prop="orderInfo.customerContactPhone" width="90"  :show-overflow-tooltip="true">-->
        <!--</el-table-column>-->
        <el-table-column label="下单时间" prop="orderInfo.commitTime" width="120" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="下单人" prop="orderInfo.commitName" width="60" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款状态" prop="customerInfowriteStatus" fixed="right" width="60" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.orderInfo.customerInfowriteStatus == 1" size="small" type="success">已付款</el-tag>
            <el-tag v-if="scope.row.orderInfo.customerInfowriteStatus == 2" size="small" type="danger">未付款</el-tag>
          </template>
        </el-table-column>
<!--        <el-table-column label="业务员" prop="orderInfo.commitName" width="60" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column label="业务经理" prop="orderInfo.commitName" width="60" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column label="客服员" prop="orderInfo.commitName" width="60" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column label="客服经理" prop="orderInfo.commitName" width="60" :show-overflow-tooltip="true">-->
<!--        </el-table-column>-->
<!--        <el-table-column fixed="right" header-align="center" width="40" label="操作">-->
<!--          <template slot-scope="scope">-->
<!--            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">-->
<!--              <el-button v-if="newAuth('cost:base:find')" type="text" size="small" @click="operatopnList('cost', 'view', scope.row)">详情</el-button>-->
<!--              <el-button v-if="newAuth('cost:base:update')" type="text" size="small" @click="operatopnList('cost', 'edit', scope.row)">费用修改</el-button>-->
<!--              <el-button slot="reference" class="referenceBtn">-->
<!--                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>-->
<!--              </el-button>-->
<!--            </el-popover>-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <cost-modification ref="cost" @refreshDataList="getDataList"></cost-modification>
    <view-image ref="viewPhoto"></view-image>
  </div>
</template>

<script>
import costModification from './detail/base-costModification-popup'
import viewImage from '@/views/commonPop/viewImage'
export default {
  data () {
    return {
      dataForm: {
        takeGoodsTime: null,
        planNo: null,
        orderNo: null,
        status: null,
        inWarehouseNo: null,
        customerName: null,
        driverName: null,
        vehiclePlate: null,
        saleIdList: null,
        saleAdminIdList: null,
        careIdList: null,
        careAdminIdList: null,
        customerInfowriteStatus: null
      },
      tableHeight: '71vh',
      listType: 6,
      dataList: [],
      pageIndex: 1,
      getIndex: -1,
      pageSize: 20,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      costImageList: [7001, 7002, 7003, 7004, 7005],
      addOrUpdateVisible: false,
      detailVisible: false,
      selectFeeData: [],
      newSaleIdList: [],
      newSaleAdminIdList: [],
      userJobMap: new Map()
    }
  },
  components: {
    costModification,
    viewImage
  },
  created () {
    this.setDefaultDate()
    this.getDataList()
    // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
    window.setTimeout(() => {
      this.tableHeight = '72vh'
    }, 1000)
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    isViews () {
      return this.todo === 'view'
    }
  },
  methods: {
    // 获取用户信息
    getUserList () {
      this.userJobMap = new Map()
      this.$http({
        url: this.$http.adornUrl(`/costInfo/findAllAByJobCodes`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.userJobMap = data
      })
    },
    setDefaultDate () {
      let end = new Date()
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      this.dataForm.takeGoodsTime = [this.dateFormatter(start, 'yyyy-MM-dd'), this.dateFormatter(end, 'yyyy-MM-dd')]
    },
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },

    openWriteOffPopup (customerInfowriteStatus) {
      this.writeOffVisible = true
      let ids = []
      this.selectFeeData.forEach(item => {
        // 已付款和申请中的不可核销
        if (item.customerInfowriteStatus !== customerInfowriteStatus) {
          ids.push(item.orderInfo.id)
        }
      })
      // if (ids.length === 0) {
      //   this.$message.error('没有符合核销的数据，请检查是否勾选正确')
      //   return
      // }
      let title = '费用核销'
      if (customerInfowriteStatus === 1) {
        title = '已付款核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/costInfo/customerInfoBatchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            customerInfowriteStatus: customerInfowriteStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
      // this.$refs.writeOff.init(this.dataForm.customerName, textsFeeWriteStatus, ids)
    },
    batchAudit (customerInfowriteStatus) {
      let ids = []
      this.selectFeeData.forEach(item => {
        if (item.customerInfowriteStatus !== customerInfowriteStatus) {
          ids.push(item.orderInfo.id)
        }
      })
      if (ids.length === 0) {
        this.$message.error('没有符合核销的数据，请检查是否勾选正确')
        return
      }
      let title = '费用核销'
      if (customerInfowriteStatus === 2) {
        title = '反核销'
      }
      this.$confirm('是否继续执行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/costInfo/customerInfoBatchWrite`),
          method: 'POST',
          data: this.$http.adornData({
            ids: ids,
            customerInfowriteStatus: customerInfowriteStatus
          })
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {
      })
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    /**
     * 点击表格变色end
     */
    examine () {

    },
    handleSelectionChange (row) {
      this.selectFeeData = row
    },
    // 查看照片
    viewPhoto (row) {
      this.$refs.viewPhoto.init(row.driverRoyalty.id, this.costImageList)
    },

    downLoad () {
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/costExport'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'listType': this.listType,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'planNo': this.dataForm.planNo,
          'orderNo': this.dataForm.orderNo,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'customerName': this.dataForm.customerName,
          'driverName': this.dataForm.driverName,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'customerInfowriteStatus': this.dataForm.customerInfowriteStatus,
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '业务员提成费用表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      let newSaleIdList =  this.dataForm.saleIdList
      let newSaleAdminIdList =  this.dataForm.saleAdminIdList
      let newCareIdList =  this.dataForm.careIdList
      let newCareAdminIdList =  this.dataForm.careAdminIdList
      this.dataListLoading = true
      this.getUserList()
      let takeGoodsTime = this.dataForm.takeGoodsTime
      let timeParam = {}
      if (takeGoodsTime && takeGoodsTime.length === 2) {
        timeParam.takeGoodsBeginDate = takeGoodsTime[0]
        timeParam.takeGoodsEndDate = takeGoodsTime[1]
      }
      this.$http({
        url: this.$http.adornUrl('/costInfo/findSalesmanCostList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'takeGoodsBeginDate': timeParam.takeGoodsBeginDate,
          'takeGoodsEndDate': timeParam.takeGoodsEndDate,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'inWarehouseNo': this.dataForm.inWarehouseNo,
          'customerName': this.dataForm.customerName,
          'customerInfowriteStatus': this.dataForm.customerInfowriteStatus,
          'saleIdList': newSaleIdList,
          'saleAdminIdList': newSaleAdminIdList,
          'careIdList': newCareIdList,
          'careAdminIdList': newCareAdminIdList
        })
      }).then(({ data }) => {
        if (data) {
          this.getSpanArr(data.list, 1)
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.costCount)
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        let preId = ''
        const values = data.map(item => {
          if (item.orderInfo.id === preId) {
            return ''
          }
          preId = item.orderInfo.id
          if (column.property && (column.property.indexOf('costInfo.') > -1 || column.property.indexOf('customerInfo.') > -1)) {
            let val = column.property.split('.')
            if (item[val[0]] === undefined || item[val[0]] === null) {
              return ''
            }
            return this.getValue(Number(item[val[0]][val[1]]))
          }
          if (column.property === 'payableAmount') {
            return this.getValue(Number(item.payableAmount))
          }
          if (column.property === 'paidAmount') {
            return this.getValue(Number(item.paidAmount))
          }
          if (column.property === 'notPayableAmount') {
            return this.getValue(Number(item.notPayableAmount))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    // 截取当前数据到小数点后两位
    numFilter (value) {
      if (value === '') {
        return value
      }
      return parseFloat(parseFloat(value).toFixed(2))
    },
    getNumValue (row, cell, value) {
      return this.getValue(value)
    },
    getValue (value) {
      if (value === undefined || value === null || value == 0) {
        return ''
      } else {
        return value
      }
    },
    getAllSummaries (costCount) {
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '', '', '', '', '',
        this.getValue(costCount.costInfo.freightFee),
        this.getValue(costCount.costInfo.addFee),
        this.getValue(costCount.customerInfo.salesmanFee),
        this.getValue(costCount.customerInfo.salesmanManagerFee),
        this.getValue(costCount.customerInfo.serviceFee),
        this.getValue(costCount.customerInfo.managerFee),
        this.getValue(costCount.payableAmount),
        this.getValue(costCount.paidAmount),
        this.getValue(costCount.notPayableAmount), '', '', '', '', '', '']
      // 创建列表行
      let tr = document.createElement('tr')
      // var itemTr = document.createElement('tr')
      // itemTr.innerHTML = document.querySelector('.el-table__footer-wrapper table tbody tr').innerHTML
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      if (document.querySelector('.el-table__fixed-footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__fixed-footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__fixed-footer-wrapper table tbody').appendChild(tr)
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (row, todo) {
      this.$nextTick(() => {
        this.$refs.detail.init(row, todo)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 禁用或启用
    disableOrEnable (row) {
      let title = row.status === 1 ? '禁用' : '启用'
      this.$confirm('确定对【' + row.simpleName + '】进行【' + title + '】操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/company/updateStatus'),
          method: 'post',
          data: {
            id: row.id,
            status: row.status === 1 ? 2 : 1
          }
        }).then(({data}) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    },
    // 删除
    deleteHandle (id) {
      let ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      })
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/sys/user/delete'),
          method: 'post',
          data: this.$http.adornData(ids, false)
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
<style lang="less">
div#pane-costManagement-supplierFee {
}
</style>
